import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../shared/styles/utils';
import { mobile } from '../../../shared/styles/media-queries';

const FamiliaBanner = ({ familyCards }) => {
  const roundUpNumber = (numberString) => {
    let formattedNumber = numberString.replace(',', '.');
    let number = parseFloat(formattedNumber);
    let roundedNumber = Math.ceil(number);
    return roundedNumber;
  };

  return (
    <ContainerBanner>
      <Container>
        <Title>TIM Black Familia</Title>
        <SubTitle>Assinaturas para a família toda curtir a vontade</SubTitle>
        <Wrapper plans={familyCards}>
          {familyCards &&
            familyCards.map((plan, index) => {
              return (
                <Item key={index} url={plan.imageUrl}>
                  <Card plan={plan}>
                    <InfoGB>
                      <p>Até &nbsp;</p> {plan.quantity}
                    </InfoGB>
                    <InfoDependent>
                      Você +{plan.dependent} dependente{plan.dependent != 1 ? 's' : ''}
                    </InfoDependent>
                    <InfoPricePerson>R$ {roundUpNumber(plan.pricePerPerson)}/mês por pessoa</InfoPricePerson>
                    <InfoPriceDependent>R$ {plan.price}/mês no total</InfoPriceDependent>
                    <Button onClick={() => MODAL.timPosUnificado({ sku: plan.sku, flow: 'familia' })} selector="btn-saiba-mais">
                      SAIBA MAIS
                    </Button>
                  </Card>
                </Item>
              );
            })}
        </Wrapper>
      </Container>
    </ContainerBanner>
  );
};

const Item = styled.li`
  transition: transform 0.2s ease-in;
  background: url(${(p) => p.url}) no-repeat;
  padding-right: 20px;

  ${mobile} {
    margin: 0 auto;
    flex: 1;
    transform: unset;
    &:hover {
      transform: unset !important;
    }
  }
`;

const Wrapper = styled.ul`
  width: 100%;
  background-color: #000;
  display: flex;
  background: black;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 20px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const ContainerBanner = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  width: 100%;
  background-color: #000;
  padding-bottom: 40px;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  font-family: TIMSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: normal;
  color: #e3e3e3;
  padding-top: 17px;
`;

const SubTitle = styled.p`
  font-family: TIMSans;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #e3e3e3;
`;

const Card = styled.li`
  width: 256px;
  height: 256px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: url(${(p) => p.url}) no-repeat;
`;

const InfoGB = styled.p`
  color: #fff;
  font-family: TIMSans;
  font-size: 26px;
  display: flex;
  align-items: baseline;

  p {
    font-size: 14px;
  }
`;

const InfoDependent = styled.p`
  color: #fff;
  font-family: TIMSans;
  font-size: 12px;
  margin-bottom: 12px;
`;

const InfoPricePerson = styled.p`
  color: #fff;
  font-size: 12px;
  font-weight: bold;
`;

const InfoPriceDependent = styled.p`
  color: #fff;
  font-size: 12px;
`;

const Button = styled.div`
  background-color: #eb0028;
  border-radius: 22px;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 33px;
  letter-spacing: normal;
  line-height: 1.38;
  text-align: center;
  width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export default FamiliaBanner;
