const social = {
  title: 'Apps inclusos na sua oferta',
  wrappers: [
    {
      border: true,
      items: [
        {
          sva: 'Facebook',
          description: 'Conecte-se, compartilhe momentos, fotos, vídeos e mensagens com as pessoas que fazem parte da sua vida.',
          icon: 'facebook',
        },
        {
          sva: 'Instagram',
          description: 'Curta e compartilhe fotos e vídeo, faça e assista stories e crie seus próprios filtros para as imagens.',
          icon: 'instagram',
        },
        {
          sva: 'X',
          description: 'Compartilhe textos, fotos, vídeos e fique sempre por dentro do que está acontecendo no Brasil e no mundo.',
          icon: 'twitter',
        },
        {
          sva: 'Whatsapp',
          description: 'Mande mensagens, fotos, vídeos e áudios para qualquer lugar do mundo.',
          icon: 'whatsapp',
        },
      ],
    },
  ],
};

export default social;
