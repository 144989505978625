import React, { useState, useEffect } from 'react';
import Accessibility from 'accessibility-options';
import { Helmet } from 'react-helmet';


import {
  Banner,
  InfoPhrases,
  Loader,
  Footer,
  NetflixPopup,
  PlansHall,
  RegulationsWrapper,
  Svas,
  TopBar,
  UFSelector,
  BannerC6,
  LgpdBanner,
  FooterFixed,
} from '../../shared/components';
import * as UFsService from '../../shared/services/ufs';
import * as RegulationsService from '../../shared/services/regulations';
import * as SvasService from '../../shared/services/svas';
import * as DataService from '../../shared/services/data';
import * as ParamsService from '../../shared/services/params';
import FamiliaBanner from './components/familia-banner';

const HomePage = () => {
  const pageName = 'pos';
  const [isUFSelectorVisible, toggleUFSelector] = useState(false);
  const [isNetflixPopupShow, toggleNetflixPopup] = useState(false);
  const [uf, changeUF] = useState('');
  const [plans, changePlans] = useState(null);
  const [content, changeContent] = useState({});
  const [isLoading, changeLoading] = useState(true);
  const [pageVersion, changePageVersion] = useState('');
  const [googleMessage, changeGoogleMessage] = useState(false);

  useEffect(() => {
    const accessibility = new Accessibility();
    changePageVersion(ParamsService.get('v'));
    const uf = UFsService.getUFByParams() || '';
    accessibility.init();
    loadData(uf);
  }, []);

  const loadData = async uf => {
    changeLoading(true);
    const googleMessageVariable = ParamsService.get('google_messages') === 'true';
    changeGoogleMessage(googleMessageVariable);
    const { plans, content } = await DataService.getData('home', uf, 'default', googleMessageVariable);
    changeUF(UFsService.getUFSelected(uf, plans[0]));
    changePlans(plans);
    changeContent(content);
    changeLoading(false);
  };

  const handleChangeUF = e => {
    const uf = e.target.value;
    changeUF(uf);
  };

  const handleCloseUFSelector = () => {
    toggleUFSelector(false);
    loadData(uf);
  };

  return (
    <div>
      <TopBar uf={uf} onUFRequest={() => toggleUFSelector(true)} />

      <Banner mobileContent={content.mobile} desktopContent={content.desktop} />

      <PlansHall plans={plans} messages={content.infoPhrases} googleMessage={googleMessage} pageName={pageName} pageVersion={pageVersion} />

      <BannerC6 />

      <Svas sva={SvasService.getSocialSvas()} />

      <FamiliaBanner familyCards={content.familyCards} />

      <Svas sva={SvasService.getTimSvas()} />

      <InfoPhrases messages={content.footerMsg} />

      <RegulationsWrapper
        regulations={RegulationsService.get(content.regulations, content.summaries, uf)}
        labelText="Faça download dos regulamentos e sumários"
      />

      <UFSelector
        visible={isUFSelectorVisible}
        handleCloseUFSelector={handleCloseUFSelector}
        selectedUF={uf}
        handleChangeSelect={e => handleChangeUF(e)}
        ufs={UFsService.getUFs()}
      />

      <FooterFixed />
      <Footer template="-default" />

      <NetflixPopup visible={isNetflixPopupShow} handleClose={() => toggleNetflixPopup(false)} />
      <LgpdBanner />

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default HomePage;
