const tim = {
  title: 'Mais benefícios do seu TIM Black',
  items: [
    {
      sva: 'Roaming Nacional',
      description: 'Receba chamadas quando estiver em outro estado sem custo adicional.',
      icon: 'roaming',
    },
    {
      sva: 'SMS',
      description: 'Envie SMS para qualquer operadora do Brasil.',
      icon: 'sms',
    },
    {
      sva: 'Ligações Ilimitadas',
      description: 'Ligações ilimitadas para qualquer operadora do Brasil.',
      icon: 'ligacoes',
    },
  ],
};

export default tim;
