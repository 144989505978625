const tim = {
  title: 'Mais benefícios do seu TIM Black',
  items: [
    {
      sva: 'INTERNET ACUMULADA',
      description: 'A internet que você não usar em um mês acumula para o mês seguinte.',
      icon: 'internet-acumulada',
    },
    {
      sva: 'Roaming Nacional',
      description: 'Receba chamadas quando estiver em outro estado sem custo adicional.',
      icon: 'roaming',
    },
    {
      sva: 'SMS',
      description: 'Envie SMS para qualquer operadora do Brasil.',
      icon: 'sms',
    },
  ],
};

export default tim;
