const social = {
  title: 'Apps inclusos na sua oferta',
  wrappers: [
    {
      border: true,
      items: [
        {
          sva: 'Facebook',
          description: 'Conecte-se, compartilhe momentos, fotos, vídeos e mensagens com as pessoas que fazem parte da sua vida.',
          icon: 'facebook',
        },
        {
          sva: 'Instagram',
          description: 'Curta e compartilhe fotos e vídeo, faça e assista stories e crie seus próprios filtros para as imagens.',
          icon: 'instagram',
        },
        {
          sva: 'X',
          description: 'Compartilhe textos, fotos, vídeos e fique sempre por dentro do que está acontecendo no Brasil e no mundo.',
          icon: 'twitter',
        },
        {
          sva: 'Whatsapp',
          description: 'Mande mensagens, fotos, vídeos e áudios para qualquer lugar do mundo.',
          icon: 'whatsapp',
        },
        {
          sva: 'Deezer Premium',
          description: 'Aplicativo com milhões de músicas para ouvir, sem descontar do pacote de internet.',
          icon: 'tim-deezer',
          extension: 'png'
        },
        {
          sva: 'Aya Audiobooks',
          description: `Aplicativo de audiolivros que possibilita ouvir em qualquer lugar, otimizando tempo e
          multiplicando conhecimento. Todo mês é disponibilizado um ou dois audiolivros de sucesso, de acordo com a oferta.`,
          icon: 'aya',
        },
        {
          sva: 'TIM Nuvem',
          description: 'Uma memória online para guardar fotos, vídeos, músicas, agenda, contatos e arquivos.',
          icon: 'tim-nuvem',
        },
        {
          sva: 'BANCAH PREMIUM + JORNAIS',
          description: 'As principais revistas do Brasil direto no seu celular, sem custo adicional.',
          icon: 'tim-banca-virtual',
        },
        {
          sva: 'TIM SEGURANÇA DIGITAL',
          description: 'Soluções digitais para segurança dos smartphones.',
          icon: 'protect',
        },
        {
          sva: 'Ensinah Premium',
          description: `Aplicativo de livros e apostilas do segmento educacional
          com cessão de direitos dos conteúdos. A versão Premium tem direito a 3
          apostilas, Contador Pomodoro e Agenda de criação de metas e estudos.`,
          icon: 'ensinah'
        },
        {
          sva: 'Equilibrah',
          description: `Aplicativo de streaming tem como objetivo proporcionar ao
          usuário o encontro constante com o bem-estar. O serviço dá acesso a vídeos
          e revistas com conteúdos relacionados a bem-estar físico, mental e social.`,
          icon: 'equilibrah'
        },
      ],
    },
  ],
};

export default social;
