import social from './mocks/home/social';
import video from './mocks/home/video';
import videoExpress from './mocks/express/video';
import tim from './mocks/home/tim';

import socialLight from './mocks/light/social';
import socialExpress from './mocks/express/social';
import timLight from './mocks/light/tim';
import timExpress from './mocks/express/tim';

const getSocialSvas = page => {
  if (page === 'light') {
    return socialLight;
  } else if (page === 'express') {
    return socialExpress;
  }
  return social;
};

const getVideoSvas = page => {
  if (page === 'express') {
    return videoExpress;
  }
  return video;
};

const getTimSvas = page => {
  if (page === 'light') {
    return timLight;
  } else if (page === 'express') {
    return timExpress;
  }
  return tim;
};

export { getSocialSvas, getVideoSvas, getTimSvas };
