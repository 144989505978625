const video = {
  title: 'O melhor conteúdo para você curtir!',
  items: [
    {
      sva: 'Netflix',
      description: 'Assista as melhores séries e filmes online diretamente no seu smartphone.',
      icon: 'netflix',
    },
    {
      sva: 'TIM vídeos by Looke',
      description: 'Assista a filmes e séries online quando e onde quiser. São mais de 12 mil filmes à sua disposição.',
      icon: 'tim-looke',
    },
    {
      sva: 'Cartoon Network',
      description: 'Os desenhos favoritos dos seus filhos em um aplicativo para smartphone.',
      icon: 'cartoon',
    },
    {
      sva: 'Esporte Interativo',
      description: 'O melhor do esporte em qualquer lugar e a qualquer hora. Jogos ao vivo, melhores lances e muito mais.',
      icon: 'ei',
    },
  ],
};

export default video;
